<template>
  <div class="h-screen bg-white p-10">
    <div class="w-50">
      <base-dropdown
        :options="filteredOptions"
        trigger-style=""
        arrow-style="absolute top-0 right-0 h-full flex items-center px-2"
        dropdown-style="mt-12 w-full max-h-48 rounded-lg bg-white shadow-md"
        @close-dropdown="closeDropdown"
      >
        <template #inputName>
          <p class="text-bb-brand-purple font-medium">this is the input name</p>
        </template>

        <template #trigger>
          <div>
            <text-input
              v-model="query"
              input-name="proto"
              input-id="proto"
              padding-right="pr-6"
              padding-left="pl-10"
            >
              <template #prefix>
                <div class="h-10 flex items-center px-2 absolute top-0 left-0 z-10">
                  <div class="h-5 rounded-sm overflow-hidden">
                    <img
                      class="h-full w-full"
                      src="/content/flags/SE.svg"
                    />
                  </div>
                </div>
              </template>
            </text-input>
          </div>
        </template>

        <template #option="{ optionData }">
          <div
            class="px-2 py-1 flex items-center gap-2 cursor-pointer hover:bg-bb-secondary-purple duration-300 ease-linear"
            @click="selectItem(optionData)"
          >
            <div class="h-5 rounded-sm overflow-hidden">
              <img
                class="h-full w-full"
                src="/content/flags/SE.svg"
              />
            </div>
            <p class="text-bb-text-secondary">{{ optionData.label }}</p>
          </div>
        </template>
      </base-dropdown>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import BaseDropdown from '@/components/dropdown/BaseDropdown.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'
export default {
  name: 'PrototypeView',
  components: {
    BaseDropdown,
    TextInput,
  },
  data() {
    return {
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
        { label: 'Option 4', value: 'option4' },
        { label: 'Option 5', value: 'option5' },
        { label: 'Option 6', value: 'option6' },
        { label: 'Option 7', value: 'option7' },
        { label: 'Option 8', value: 'option8' },
        { label: 'Option 9', value: 'option9' },
        { label: 'Option 10', value: 'option10' },
      ],
      query: '',
      selectedItem: null,
    }
  },
  computed: {
    filteredOptions() {
      if (!this.query.trim() || this.query === this.selectedItem?.label) return this.options

      const rules = {
        isCaseSensitive: false,
        keys: ['label'],
        threshold: 0.3,
      }
      const fuse = new Fuse(this.options, rules)

      const result = fuse.search(this.query).map(result => result.item)

      return result || []
    },
  },
  mounted() {
    this.selectItem(this.options[0])
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      this.query = item.label
    },
    closeDropdown() {
      if (this.query !== this.selectedItem?.label) {
        this.query = this.selectedItem?.label || ''
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
